.App {
  text-align: center;
}

.side-bar {
  /* background-color: rgba(238, 238, 238, 0.9); */
  font-weight: bold !important;
}

.logo-wrapper {
  margin: 0;
  background-color: #282c34;
  justify-content: center;
  padding: 0;
  line-height: 0;
  font-size: 0;
}

.app-logo {
  /* align-items: center; */
  justify-content: center;
  height: 64px;
  padding: 10px 15px;
  margin: 0 0 15px 0;
  line-height: 0;
  font-size: 0;
}

.card-folder {
  /* font-size: 0; */
  margin-top: 20px;
}

.overflowhidden {
  overflow: hidden;
}

.cs-main-container {
  height: 96.5% !important;
}

.cs-main-container .question {
  background: transparent;
  border: 1px solid #325278;
  border-radius: 0;
  margin-top: -15px;
}

.cs-main-container .cs-message--incoming {
  display: inline-block;
  background: white;
  border-radius: 0 0.7em 0.7em 0;
}

.cs-main-container .cs-message--incoming .cs-message__content {
  background: transparent !important;
  border-width: 1px;
}

.cs-main-container .cs-message--outgoing .cs-message__content {
  background-color: #eef7ff !important;
  border-width: 1px;
}

.cs-message-input__content-editor-wrapper {
  background-color: white !important;
}

.cs-message-input__content-editor {
  background-color: white !important;
}

.custom-zoom [data-rmiz-modal-overlay],
.custom-zoom [data-rmiz-modal-img] {
  transition-duration: 0.2s;
}

.custom-zoom [data-rmiz-modal-img] {
  touch-action: manipulation;
  pointer-events: none;
}

.custom-zoom [data-rmiz-modal-overlay="visible"] {
  background-color: #555;
}

.shine {
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: -100px;
    left: -100px;
    width: 50px;
    height: 50px;
    background-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 10%,
      rgba(255, 255, 255, 1) 100%,
      rgba(255, 255, 255, 0) 0%
    );

    /* アニメーション */
    animation-name: shine;
    animation-delay: -1s;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}

@keyframes shine {
  0% {
    transform: scale(0) rotate(25deg);
    opacity: 0;
  }

  30% {
    transform: scale(0) rotate(25deg);
    opacity: 0;
  }

  50% {
    transform: scale(1) rotate(25deg);
    opacity: 1;
  }

  100% {
    transform: scale(50) rotate(25deg);
    opacity: 0;
  }
}

.temporary-animation {
  -webkit-animation-iteration-count: 6;
  animation-iteration-count: 6;
}
