@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.main-theme {
  color: white;
  background-color: #325278;
}

.main-theme-reverse {
  color: #325278;
  background-color: white;
}

.main-theme-reverse-border {
  color: #325278;
  background-color: white;
  border-color: #325278;
}

.main-theme-reverse-sub {
  color: #325278;
  background-color: #dce2e8;
}

/* @layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
} */
